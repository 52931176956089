import "./App.scss";
import FooterComponent from "./Components/Footer/Footer";

function App() {
  return (
    <div className="App">
      {/* <div className="App-container">HI</div> */}
      <div className="App-header aine-text notranslate">AINE</div>
      <div className="media-text">Media</div>
      <FooterComponent />
    </div>
  );
}

export default App;
